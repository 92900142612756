import { defaultHeaders, fetchJson } from 'common/http';

/***********************************************************/
/* This function, `handleSelection`, is designed to handle the selection of a chat prompt.
/*
/* @param question {string} - The user's question/input.
/* @param chatHistory {any[]} - The history of the chat session.
/* @param apiCall {Function} - The function to call the API.
/* @param datasetData {any} - The dataset metadata.
/* @param flags {string[]} - An array of flags to be used in the history object. Default is an empty array.
/*
/* The function performs the following steps:
/* 1. Maps over the `chatHistory` array to create a new array, `chatHistoryWithoutIds`, that only includes the `chatObject` of each chat item.
/* 2. Creates a `data` object that includes the `datasetData`, the `question`, and the `chat_history`. If `chatHistory` is not empty, `chat_history` is set to `chatHistoryWithoutIds`, otherwise it's an empty array.
/* 3. Calls the `apiCall` function with `data`, `question`, and `flags` as arguments.
/***********************************************************/
export const handleSelection = (question: string, chatHistory: any, apiCall: any, datasetData: any, flags: string[] = []) => {
  // so this is part of the code to add ids to the chat history objects without sending them to the AI
  // the idea was not to use index as the key on the maps in an effort to fix the display bug with the
  // ForgeMenu component, but it didn't work.  I'm leaving this here in case we want to revisit it.
  const chatHistoryWithoutIds = chatHistory.map((chat: any) => {
    return chat.chatObject;
  });
  const data = {
    ...datasetData,
    'question': question,
    'chat_history': chatHistory.length > 0 ? chatHistoryWithoutIds : []
  };
  apiCall(data, question, flags);
};

/***********************************************************/
/* This function, `getURLFromSoql`, is designed to format a string for use in a URL.
/*
/* @param string {any} - The string to be formatted.
/*
/* The function performs the following steps:
/* 1. Replaces all occurrences of double newlines, single newlines, single quotes, and spaces
/*    in the string with appropriate URL-encoded characters.
/* 2. Replaces all occurrences of closing parentheses and opening parentheses with their
/*    respective URL-encoded characters.
/* 3. Checks if the modified string includes '```soql'. If it does, it splits the string by
/*    '```soql', takes the second part, splits that by '```', takes the first part, and trims
/*    any leading or trailing whitespace. This is done to extract the SOQL query from a string
/*    that includes it.
/* 4. If the modified string does not include '```soql', it simply returns the modified string.
/***********************************************************/
export const getURLFromSoql = (string: any) => {
  const modString = string.replace(/\n\n/g, ' ').replace(/\n/g, ' ').replace(/'/g, '"').replace(/ /g, '%20')
  .replace(/\)/g, '%29').replace(/\(/g, '%28');

  return modString.includes('```soql')
    ? modString.split('```soql')[1].split('```')[0].trim()
    : modString;
};

/***********************************************************/
/* This function, `removeJsonCodeBlock`, is designed to format a string by removing specific characters.
/*
/* @param string {any} - The string to be formatted.
/*
/* The function performs the following steps:
/* 1. Replaces all occurrences of '```soql' in the string with an empty string.
/* 2. Replaces all occurrences of '```' in the string with an empty string.
/* 3. Replaces all occurrences of three spaces in the string with '",\n  '.
/* 4. Returns the modified string.
/***********************************************************/
export const removeJsonCodeBlock = (string: any) => {
  return string.replace(/```soql/g, '').replace(/```/g, '')
    .replace(/[ ]{3}/g, '",\n  ');
};

/***********************************************************/
/* This function, `primaryAPICall`, is designed to make an API call to the azure endpoint.
/*
/* @param data {any} - The data to be sent to the API in the request body.
/*
/* The function performs the following steps:
/* 1. Calls the `fetchJson` function with the URL of the API endpoint and an options object.
/* 2. The options object includes the HTTP method ('POST'), headers (including default headers,
/*    'Content-Type' set to 'application/json'), credentials set
/*    to 'same-origin', and the body of the request (which is the stringified `data`).
/* 3. Returns the result of the `fetchJson` call, which should be a Promise resolving to the
/*    JSON response from the API.
/***********************************************************/
export const primaryAPICall = async (data: any) => {
  return fetchJson('/azure_score_proxy/soql', {
    method: 'POST',
    headers: {
      ...defaultHeaders,
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(data)
  });
};

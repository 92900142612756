import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FlyoutFactory } from 'common/components/legacy';
import { localizeLink } from 'common/locale';
import I18n from 'common/i18n';

interface BootstrapAlertProps {
  bootstrapUrl: string;
  onClickBootstrap?: () => void;
}

export class BootstrapAlert extends Component<BootstrapAlertProps> {
  uniqueId: string | undefined;
  UNSAFE_componentWillMount() {
    // eslint-disable-line camelcase
    this.uniqueId = _.uniqueId();
  }

  // Using components.attachTo here isn't possible.  Until common/components is able to handle
  // multiple attachTo's and scope them properly, we must use the factory manually.
  componentDidMount() {
    FlyoutFactory(ReactDOM.findDOMNode(this)); // eslint-disable-line
  }

  render() {
    const { bootstrapUrl, onClickBootstrap } = this.props;
    const isDisabled = !_.isString(bootstrapUrl);
    let className = 'btn btn-sm btn-alternate-2';
    let flyoutId;
    let flyout;

    if (isDisabled) {
      className = classNames(className, 'btn-disabled');
      flyoutId = `bootstrap-flyout-${this.uniqueId}`;
      flyout = (
        <div id={flyoutId} className="flyout flyout-hidden">
          <section className="flyout-content">
            <p>{I18n.t('dataset_landing_page.bootstrap_disabled_notice')}</p>
          </section>
          <footer className="flyout-footer" />
        </div>
      );
    }

    return (
      <div className="alert default bootstrap-alert">
        <div className="message">{I18n.t('dataset_landing_page.bootstrap_message')}</div>

        <a
          href={isDisabled ? undefined : localizeLink(bootstrapUrl)}
          className={className}
          data-flyout={flyoutId}
          onClick={isDisabled ? undefined : onClickBootstrap}
          tabIndex={0}
          role="link"
        >
          {I18n.t('dataset_landing_page.bootstrap_button')}
        </a>
        {flyout}
      </div>
    );
  }
}

function mapDispatchToProps() {
  return {
    onClickBootstrap() {
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    }
  };
}

export default connect(null, mapDispatchToProps)(BootstrapAlert);

import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import App from './App';
import { browserHistory, Route, Router, Redirect, IndexRoute } from 'react-router';
import { DslpTab } from './components/TabViewSwitcher';

const createRoutes = () => {
  const baseUrl = '/(:locale/):category/:name/:fourfour';

  return (
    // Because the route is entirely variables you cannot tell difference between a route with a locale vs a route without a locale but with the tab (if the tab was a variable)
    // as these both would have four segments. Thus we explicity define all the possible routes for each tab.
    // The rails routing enforces that only these options make it through.
    <>
      <Route path={`${baseUrl}/${DslpTab.About}`} component={App} />
      <Route path={`${baseUrl}/${DslpTab.Data}`} component={App} />
      <Route path={`${baseUrl}/${DslpTab.RelatedContent}`} component={App} />

      {/* Redirect from legacy /about urls */}
      <Redirect from={`${baseUrl}/about`} to={`${baseUrl}/${DslpTab.About}`} />

      {/* Redirect to about_tab if no tab is specified */}
      <Redirect from={baseUrl} to={`${baseUrl}/${DslpTab.About}`} />
    </>
  )
}

const Root = ({ store }) => {
  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        {createRoutes()}
      </Router>
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;

import _ from 'lodash';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { handleAlertSaveSuccess } from '../actions/view';
import { associateChildToParent } from '../actions/associateCollections';
import CommonMetadataTable from 'common/components/MetadataTable';
import { localizeLink } from 'common/locale';
import WatchDatasetButton from './WatchDatasetButton/WatchDatasetButton';
import { FeatureFlags } from 'common/feature_flags';
import {
  currentUserIsSiteMember,
  currentUserIsSuperAdmin,
  currentUserIsLoggedIn,
  currentUserHasRight
} from 'common/current_user';
import DomainRights from 'common/types/domainRights';

const isUSAID = () => FeatureFlags.value('usaid_features_enabled') === true;

export function mapStateToProps(state) {
  const view = state.view || {};
  const { coreView, customMetadataFieldsets } = view;

  const enableCreateAlertButtonForAllUser =
    FeatureFlags.value('enable_alert_notifications_for_all_users') === true;
  const enableCreateAlertButtonForAdmin =
    FeatureFlags.value('enable_alert_notifications_for_admin_users') === true;
  const enableCreateAlertButtonForRolledUser =
    FeatureFlags.value('enable_alert_notifications_for_role_users') === true;


  // EN-19924: USAID special feature. Only enable Associated Assets if the USAID feature flag is on,
  // and we are on a Primer page for a published table or blob
  const associatedAssetsAreEnabled = () => {
    // if editMetadataUrl is null, a user does not have permissions to edit the view
    if (view.editMetadataUrl) {
      const { publicationStage } = view.coreView;
      const { isParent } = view.coreView.metadata || {};

      if (!isUSAID()) return false;
      if (publicationStage !== 'published') return false;
      if (isParent === false) return true;
    }


    /*
      11/20/17
      This is the case where the user is on a Primer page for an href child, updating its parent.
      We pushed back on USAID to implement this functionality farther down the line.
    */
    // if (viewType === 'href') return true;

    return false;
  };

  const useDataAssetStrings = () => {
    const { isParent } = view.coreView.metadata || {};
    return isUSAID() && isParent === true;
  };

  let showCreateAlertButton = false;
  let showMuteDatasetButton = false;

  if (currentUserIsLoggedIn()) {
    showMuteDatasetButton = true;
    if (enableCreateAlertButtonForAllUser) {
      showCreateAlertButton = true;
    } else if (enableCreateAlertButtonForAdmin && (currentUserIsSuperAdmin() || currentUserHasRight(DomainRights.manage_users))) {
      showCreateAlertButton = true;
    } else if (enableCreateAlertButtonForRolledUser && (currentUserIsSiteMember() || currentUserIsSuperAdmin())) {
      showCreateAlertButton = true;
    }
  }

  // We're mutating coreView for now (follow-up ticket to fix is EN-24645).
  // We do the translating in rails right now. Would need to reproduce in javascript.
  const coreViewCopy = _.cloneDeep(coreView);
  const license = _.get(coreViewCopy, 'license', {});
  license.name = view.licenseName || license.name || '';
  coreViewCopy.license = license;

  return ({
    analysisId: view.analysisId,
    associatedAssetsApiCalls: state.associateCollections,
    enableAssociatedAssets: associatedAssetsAreEnabled(),
    localizeLink,
    coreView: coreViewCopy,
    customMetadataFieldsets: customMetadataFieldsets || [],
    disableContactDatasetOwner: view.disableContactDatasetOwner,
    renderWatchDatasetButton() {
      if (
        _.get(window, 'sessionData.email', '') !== '' &&
        _.get(window, 'serverConfig.enableNotifications')
      ) {
        return (<WatchDatasetButton view={view} useDataAssetStrings={useDataAssetStrings()} />);
      } else {
        return null;
      }
    },
    showCreateAlertButton,
    showMuteDatasetButton,
    useDataAssetStrings: useDataAssetStrings()
  });
}

function mergeProps(stateProps, { dispatch }) {
  return {
    ...stateProps,
    onExpandTags: () => {
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    },

    onExpandMetadataTable: () => {
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    },

    onSaveAssociatedAssets: associatedAssets => {
      const associatedAssetId = _.get(associatedAssets[0], 'id');
      if (associatedAssetId) {
        dispatch(associateChildToParent(associatedAssetId, stateProps.coreView));
      }
    },
    onAlertSaveSuccess() {
      dispatch(handleAlertSaveSuccess());
    }
  };
}

export default connect(mapStateToProps, null, mergeProps)(CommonMetadataTable);

import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import subscriptionApi from 'common/components/MuteDatasetButton/subscriptionApi';
import { WATCH_DATASET_ACTIVITY } from 'datasetLandingPage/lib/constants';
import { onSubscriptionChange as subscriptionChange } from '../../actions/view';

export class WatchDatasetButton extends Component {
  onWatchDatasetButtonClick = (event) => {
    event.preventDefault();

    const { view, onSubscriptionChange } = this.props;
    const promise = view.subscribed ?
      subscriptionApi.unsubscribe(view.subscriptionId) :
      subscriptionApi.subscribe(view.id, WATCH_DATASET_ACTIVITY);

    promise.then((subscribedResult) => {
      onSubscriptionChange(_.get(subscribedResult, 'id', null));
    }).
    catch(() => onSubscriptionChange(null));
  }

  render() {
    const { subscribed } = this.props.view;
    const watchDatasetFlagIcon = classNames('flag-icon',
      subscribed ? 'socrata-icon-watched' : 'socrata-icon-watch'
    );
    const type = this.props.useDataAssetStrings ? 'data_asset' : 'dataset';
    const key = subscribed ? `unwatch_${type}` : `watch_${type}`;

    return (
      <div className="watch-dataset-button">
        <label
          onClick={(event) => this.onWatchDatasetButtonClick(event)}
          className="inline-label manage-prompt-button btn btn-sm btn-default"
          data-flyout="watch-dataset-button-flyout">
          <span className={watchDatasetFlagIcon}></span>
          <span className="checkbox-with-icon-label">
            {I18n.action_buttons[key]}
          </span>
        </label>

        <div id="watch-dataset-button-flyout" className="flyout flyout-hidden flyout-block-label">
          <section className="flyout-content">{I18n.action_buttons.watch_dataset_flyout}</section>
        </div>
      </div>
    );
  }
}

WatchDatasetButton.propTypes = {
  onSubscriptionChange: PropTypes.func,
  useDataAssetStrings: PropTypes.bool,
  view: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    onSubscriptionChange(subscriptionId) {
      dispatch(subscriptionChange(subscriptionId));
    }
  };
}

export default connect(null, mapDispatchToProps)(WatchDatasetButton);

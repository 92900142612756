import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './DataWiz.scss';
import { getSiteChromeHeight, iaSiteChromeHeaderFixed } from 'common/util/siteMeasurements';
import { getURLFromSoql, handleSelection, primaryAPICall, removeJsonCodeBlock } from './PrimerChatbotAIHelpers';
import { ForgeButton, ForgeCard, ForgeDrawer, ForgeExpansionPanel, ForgeIcon, ForgeIconButton, ForgeOpenIcon, ForgeScaffold, ForgeTextField, ForgeToolbar } from '@tylertech/forge-react';

export interface aiDomainDataProps {
  fourfour: string,
  domain: string,
  exploreUrl: string
}
interface DataWizDrawerProps {
  open: boolean;
  dataWizPathData: aiDomainDataProps;
}

const DataWiz = (props: DataWizDrawerProps) => {
  const [isOpen, setIsOpen] = React.useState(props.open);
  const [displayWarning, setDisplayWarning] = useState(true);
  const [chatID, setChatID] = useState(0);
  const [chatHistory, setChatHistory] = useState<{ id: number, chatObject: { inputs: { domain: string, fourfour: string, question: string, type: number }, outputs: { answer: string, line_number: number } }, flags: string[] }[]>([]);
  const [loading, setLoading] = useState(false);
  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);
  const [expansionPanelOpen, setExpansionPanelOpen] = useState(true);
  const [countdown, setCountdown] = useState<any>(null);
  /********************************************************/
  /*      This metadata will be passed in via props       */
  /*      Secondary datasets are:                         */
  /*      FourFour: 'qxh8-f4bd', 'w729-m8j6'              */
  /*      Domain: 'internal.opendata.clermontauditor.org' */
  /*      ExploreUrl: 'https://data.wa.gov/Health/Health-Care-Provider-Credential-Data/qxh8-f4bd/explore/query/'
  /********************************************************/
  // const fourfour = 'gpri-47xz';
  // const domain = 'data.wa.gov';
  // const exploreUrl = 'https://data.wa.gov/Consumer-Protection/Attorney-General-Consumer-Complaints/gpri-47xz/explore/query/';
  const { fourfour, domain, exploreUrl } = props.dataWizPathData;

  const datasetMetadata = {
    'fourfour': fourfour,
    'domain': domain,
    'type': 0,
    'question': '',
    'chat_history': []
  };

  /***********************************************************/
  /* on function load, this uses the dataset metadata to ask */
  /* the LLM for 4 suggested questions about the dataset     */
  /***********************************************************/
  useEffect(() => {
    const getSuggestedPrompts = () => {
      setLoading(true);
      return primaryAPICall(datasetMetadata).then((response: any) => {
        // this should no longer be needed. I'm electing to leave it in for the demo. Just in case.
        const answers = removeJsonCodeBlock(response.answer);
        const questions = JSON.parse(answers).questions;
        setLoading(false);
        setSuggestedPrompts(questions);
      });
    };

    getSuggestedPrompts();
  }, []);

  const onDismiss = () => {
    setIsOpen(false);
  };

  const dismissWarning = () => {
    setDisplayWarning(false);
  };

  /***********************************************************/
  /* This is an asynchronous function that makes an API call and
  /* updates the application state based on the response.
  /*
  /* @param data {any} - The data to be sent to the API.
  /* @param question {string} - The user's question/input.
  /* @param flags {string[]} - An array of flags to be used in the history chat object.
  /*
  /* The function performs the following steps:
  /* 1. Sets the loading state to true, indicating that the API call is in progress.
  /* 2. Makes an API call with the provided data.
  /* 3. Once the API call is resolved, sets the loading state to false.
  /* 4. Increments the chatID state by 1.
  /* 5. Updates the chatHistory state by adding a new object to the array. This object includes the current chatID, a chatObject with input and output details, and the provided flags.
  /* 6. Calls the setScrollPosition function to adjust the UI scroll position.
  /***********************************************************/
  const suggestedPromptApiCall = async (data: any, question: string, flags: string[]) => {
    setLoading(true);
    return primaryAPICall(data).then((response: any) => {
      setLoading(false);
      setChatID(chatID + 1);
      setChatHistory([...chatHistory, {
        'id': chatID,
        'chatObject': {
          'inputs': {
            'fourfour': fourfour,
            'domain': domain,
            'question': question,
            'type': 0
          },
          'outputs': {
            'answer': response.answer,
            'line_number': 0
          }
        },
        'flags': flags
      }]);
      setScrollPostion();
    });
  };

  const setScrollPostion = () => {
    const questions = document.getElementsByClassName('question-wrapper');
    questions[questions.length - 1].scrollIntoView();
  };

  /***********************************************************/
  /* This function renders a list of suggested prompts as buttons.
  /*
  /* The function performs the following steps:
  /* 1. Iterates over the `suggestedPrompts` array. Each item in the array is a string representing a prompt.
  /* 2. For each prompt, it returns a `ForgeButton` component with a key equal to the prompt string.
  /* 3. Inside the `ForgeButton`, it includes a button with a click event handler. When clicked, it closes the expansion panel, and calls the `handleSelection` function with the prompt, `chatHistory`, `suggestedPromptApiCall`, and `datasetMetadata` as arguments.
  /* 4. The button displays the prompt string as its text.
  /***********************************************************/
  const renderSuggestedPrompts = () => {
    return _.map(suggestedPrompts, (prompt: string) => {
      return (
        <ForgeButton key={prompt} className="fg-btn-suggested-prompt">
          <button className='btn-suggested-prompt' type="button" onClick={() => {
            setExpansionPanelOpen(false);
            handleSelection(prompt, chatHistory, suggestedPromptApiCall, datasetMetadata);
          }}>
            <span>{prompt}</span>
          </button>
        </ForgeButton>
      );
    });
  };

  /***************************************/
  /* This currently has no guardrails   */
  /* for the user input, bad engineer!  */
  /* This function, `handlePromptInput`, is designed to handle user input for a prompt in a chat-like interface.
  /*
  /* @param e {any} - The event object from the input field.
  /*
  /* The function performs the following steps:
  /* 1. Checks if there's an existing countdown (timeout). If there is, it clears the countdown.
  /* 2. Sets a new countdown (timeout) that will execute after 2 seconds. This is to prevent the API call from firing on every keystroke.
  /* 3. Inside the countdown, it retrieves the question from the event's target value.
  /* 4. Calls the `handleSelection` function with the question, `chatHistory`, `suggestedPromptApiCall`, and `datasetMetadata` as arguments.
  /* 5. Closes the expansion panel.
  /* 6. Calls the `setScrollPostion` function to adjust the UI scroll position.
  /***************************************/
  const handlePromptInput = (e: any) => {
    if (countdown) clearTimeout(countdown);
    setCountdown(setTimeout(() => {
      const question = e.target.value;
      handleSelection(question, chatHistory, suggestedPromptApiCall, datasetMetadata);
      setExpansionPanelOpen(false);
      setScrollPostion();
    }, 2000));
  };

  // get header height to calculate where to display the drawer
  const siteChromeHeight = iaSiteChromeHeaderFixed() ? getSiteChromeHeight() : 0;
  const minHeight = window.innerHeight;

  return (
    <ForgeDrawer id="forge-datawiz-drawer" slot={'right'} open={isOpen}
      style={{'top':siteChromeHeight, 'height':minHeight - siteChromeHeight}}>
      <ForgeScaffold>
        <ForgeToolbar slot="header">
          <h1>
            <ForgeIcon name="wizard_hat" />
            <span>DataWiz</span>
          </h1>
          <ForgeIconButton slot="end">
            <button data-testid="export-modal-x" className="tyler-icons" onClick={() => onDismiss()}>
              close
            </button>
          </ForgeIconButton>
        </ForgeToolbar>

        <div slot="body" className="forge-typography--body2 chat-bubbles" tabIndex={0}>
          <div className='dataWiz-hello answer-bubble'>
            Hi, I’m the DataWiz!
            I'm an AI bot built to help you make the most of your data. Just type in your questions or prompts related to the dataset you're working with, and I'll do my best to provide you with insights, analysis, and assistance.
          </div>
          {chatHistory.map((chat: any) => {
            const res = chat.chatObject.outputs;
            const soql = getURLFromSoql(res.answer);

            const seg1 = res.answer.includes('```soql') ? res.answer.split('```soql')[0] : null;
            const seg2 = res.answer.includes('```soql') ? res.answer.split('```soql')[1].split('```')[0] : null;
            const seg3 = res.answer.includes('```soql') ? res.answer.split('```soql')[1].split('```')[1] : null;
            const url = exploreUrl + soql;

            return (
              <div key={chat.chatObject.inputs.question}>
                <div className='question-wrapper'>
                  <div className='question-bubble question-bubble-arrow'>{chat.chatObject.inputs.question}</div>
                </div>
                <div className='answer-bubble'>
                  <span>{seg1}</span>
                  <span><a className="answerSoqlLink" href={url} target="_blank">{seg2}</a></span>
                  <span>{seg3}</span>
                </div>
              </div>
            );
          })}
        </div>

        <ForgeCard outlined className="fieldset-card" slot="body-footer">
          <ForgeExpansionPanel open={expansionPanelOpen} id={'suggested-prompts-expansion-panel'}>
            <div className='fieldset-group-header' slot="header">
              Suggested Prompts
              <ForgeOpenIcon />
            </div>
            <div className="suggested-prompts">
              {renderSuggestedPrompts()}
            </div>
          </ForgeExpansionPanel>
        </ForgeCard>

        <div slot="footer">
          <ForgeTextField className={'prompt-input-field'}>
            <input type="text" id="input-text-01" onChange={(e) => handlePromptInput(e)} />
            <label htmlFor="input-text-01" slot="label">Enter prompt</label>
            <ForgeIcon name="wizard_hat" slot="trailing" />
          </ForgeTextField>
          {displayWarning && <div className='warning dataWizWarning'>
            <div>
              <div className='aiDisclaimer'>*These questions are generated, and answers are collected, automatically by our AI LLM.*</div>
              <div className='aiDisclaimer'>*This feature is in beta testing!*</div>
            </div>
            <ForgeIconButton slot="end">
              <button data-testid="export-modal-x" className="tyler-icons" onClick={() => dismissWarning()}>
                close
              </button>
            </ForgeIconButton>
          </div>}
        </div>
      </ForgeScaffold>
    </ForgeDrawer>
  );
};

export default DataWiz;

import { ForgeButton, ForgeIcon, ForgeScaffold, ForgeTab, ForgeTabBar, ForgeView, ForgeViewSwitcher } from '@tylertech/forge-react';
import { ITabBarActivateEventData } from '@tylertech/forge';
import React, { CSSProperties, FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import I18n from 'common/i18n';
import ActionButton from './ActionButton';
import { View } from 'common/types/view';
import { TOGGLE_OPTIONS } from 'common/components/ExportModal';
import { getSiteChromeHeight, iaSiteChromeHeaderFixed } from 'common/util/siteMeasurements';
import { browserHistory } from 'react-router';
import FeatureFlags from 'common/feature_flags';
import DataWiz from '../../aiBot/DataWiz';

const i18nScope = { scope: 'dataset_landing_page.tabs' };

type TabViewerProps = PropsWithChildren<unknown> & {
  view: View,
  openExportDialog: (exportModalDefaultToggle: TOGGLE_OPTIONS) => void,
  currentTab: DslpTab
  openDataWizDrawer: () => void;
  isDataWizOpen: boolean;
};

export enum DslpTab {
  About = 'about_data',
  Data = 'data_preview',
  RelatedContent = 'related_content'
}

const tabIndexes = {
  [DslpTab.About]: 0,
  [DslpTab.Data]: 1,
  [DslpTab.RelatedContent]: 2
};

const indexedTabs = {
  0: [DslpTab.About],
  1: [DslpTab.Data],
  2: [DslpTab.RelatedContent]
};

const TabViewSwitcher: FunctionComponent<TabViewerProps> = ({
  children,
  view,
  openExportDialog,
  currentTab,
  openDataWizDrawer,
  isDataWizOpen
}) => {
  // These refs are used to set focus manually
  const aboutTabRef = useRef<any>();
  const dataTabRef = useRef<any>();
  const relatedContentTabRef = useRef<any>();
  const [index, setIndex] = useState<number>(tabIndexes[currentTab]);
  useEffect(() => {
    setIndex(tabIndexes[currentTab]);
    setTabFocus(currentTab);
  }, [currentTab]);
  const showWAStateAIDemo = FeatureFlags.value('washington_state_ai_demo');

  // we are setting this manually otherwise it can get screwed up with things like the back button
  const setTabFocus = (tab: DslpTab) => {
    switch (tab) {
      case DslpTab.About: {
        if (aboutTabRef.current) aboutTabRef.current.focus();
        break;
      }
      case DslpTab.Data: {
        if (aboutTabRef.current) dataTabRef.current.focus();
        break;
      }
      case DslpTab.RelatedContent: {
        if (aboutTabRef.current) relatedContentTabRef.current.focus();
        break;
      }
    }
  };

  const onTabClick = (tabIndex: number) => {
    setIndex(tabIndex);
    const pathRegexp = /(?<path>\/(?:dataset|.+)\/(?:.+)\/(?:\w{4}-\w{4}))/;
    const path = window.location.pathname.match(pathRegexp)?.groups?.path || '';

    if (path) {
      browserHistory.push(`${path}/${indexedTabs[tabIndex]}`);
    }
  };

  const viewSwitcherProps = {
    animationType: 'none',
  };
  const viewStyles: CSSProperties = {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  };

  // if the view is from href or blob we want to hide some of the tabs
  const viewHrefOrBlob = !!view.isBlobby || !!view.isHref;

  const getHeaderOffset = () => {
    if (iaSiteChromeHeaderFixed()) return getSiteChromeHeight();
     // unrolled does not use a sticky header, so we don't care how big it is it scrolls off screen
    return '0';
  };

  const renderDataWizChatbot = () => {
    if (showWAStateAIDemo) {
      return (
        <ForgeButton type={'flat'} id="data-wiz-open-button" data-testid="data-wiz-open-button">
          <button type="button" onClick={() => openDataWizDrawer()}>
            <ForgeIcon name="wizard_hat" />
            <span>DataWiz</span>
          </button>
        </ForgeButton>
      );
    }
  };

  const dataWizPathData = {
    fourfour: view.id,
    // this is so hacky. Please don't do this in production code.
    // TODO: remove this hacky code and do it right with error handling and alternate paths
    // this is a temporary solution to get the domain name for the datawiz chatbot
    // coreView is the only place where we have the domain name and while the attribute
    // itself is not required, it is assumed to be present all through out our codebase.
    // Ideally, we should have a better way to get the domain name and exploreUrl.
    domain: view.coreView!.domainCName!,
    exploreUrl: view.exploreUrl!
  };
  return (
    <div id="ViewSwitcher" className={'view-switcher-wrapper'}>
      <div id='view-switcher-nav-bar' className='view-switcher-header'
        style={{top: getHeaderOffset()}}
      >
        <ForgeTabBar className='tab-bar-container' underline layoutMode={'clustered'} activeTab={index} on-forge-tab-bar-activate={(event: CustomEvent<ITabBarActivateEventData>) => onTabClick(event.detail.index)}>
          <ForgeTab className={'primer-tabs first-tab'} data-testid='about-tab' ref={aboutTabRef}>{I18n.t('about', i18nScope)}</ForgeTab>
          { !viewHrefOrBlob && <ForgeTab className={'primer-tabs'} data-testid='data-tab' ref={dataTabRef}>{I18n.t('data', i18nScope)}</ForgeTab> }
          { !viewHrefOrBlob && <ForgeTab className={'primer-tabs'} data-testid='related-content-tab' ref={relatedContentTabRef}>{I18n.t('related_content', i18nScope)}</ForgeTab> }
        </ForgeTabBar>
        <div>
          <div className='action-button-wrapper'>
          <ActionButton openExportModalToApi={() => {
            openExportDialog(TOGGLE_OPTIONS.API_ENDPOINT);
          }}/>
          {!viewHrefOrBlob &&
            <ForgeButton type='outlined' className='export-button' data-testid='export-data-button'>
             <button onClick={() => openExportDialog(TOGGLE_OPTIONS.DOWNLOAD_FILE)}>
               {I18n.t('export', i18nScope)}
             </button>
           </ForgeButton>
          }
          {renderDataWizChatbot()}
          </div>
        </div>
      </div>

      <ForgeScaffold className="tab-view-switcher-scaffold">
        <ForgeViewSwitcher slot={'body'} {...viewSwitcherProps} index={index}>
          <ForgeView role="tabpanel">
            <div style={viewStyles} className="forge-typography--body1">{children![0]}</div>
          </ForgeView>
          <ForgeView role="tabpanel">
            <div style={viewStyles} className="forge-typography--body1">{children![1]}</div>
          </ForgeView>
          <ForgeView role="tabpanel">
            <div style={viewStyles} className="forge-typography--body1">{children![2]}</div>
          </ForgeView>
        </ForgeViewSwitcher>
        {isDataWizOpen && showWAStateAIDemo && <DataWiz open={true} dataWizPathData={dataWizPathData}/>}
      </ForgeScaffold>
    </div>
  );
};
export default TabViewSwitcher;

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Notice from './Notice';

export class DismissibleNotice extends Component {
  constructor(props) {
    super(props);

    this.state = { isDismissed: true };

    _.bindAll(this, 'onClickDismiss');
  }

  UNSAFE_componentWillMount() { // eslint-disable-line camelcase
    let hasDismissedNotice = true;

    const { noticeTypeName, noticeIdentifier } = this.props;

    try {
      const noticesClosed = JSON.parse(
        window.sessionStorage.getItem(noticeTypeName)
      );
      hasDismissedNotice = noticesClosed[noticeIdentifier];
    } catch (e) {
      hasDismissedNotice = false;
    }

    this.setState({
      isDismissed: hasDismissedNotice
    });
  }

  onClickDismiss() {
    try {
      const { noticeTypeName, noticeIdentifier } = this.props;

      let noticesClosed = JSON.parse(
        window.sessionStorage.getItem(noticeTypeName)
      );
      noticesClosed = noticesClosed || {};
      noticesClosed[noticeIdentifier] = true;
      window.sessionStorage.setItem(
        noticeTypeName,
        JSON.stringify(noticesClosed)
      );
    } finally {
      this.setState({
        isDismissed: true
      });
    }
  }

  render() {
    if (this.state.isDismissed) {
      return null;
    }

    return (
      <Notice
        children={this.props.children}
        onClickDismiss={this.onClickDismiss}
        additionalClasses={this.props.additionalClasses} />
    );
  }
}

DismissibleNotice.propTypes = {
  noticeTypeName: PropTypes.string.isRequired,
  noticeIdentifier: PropTypes.string.isRequired,
  additionalClasses: PropTypes.arrayOf(PropTypes.string),
  onAlertSavedNoticeClose: PropTypes.func,
  children: PropTypes.node
};


export default DismissibleNotice;

import { connect } from 'react-redux';
import BlobPreview from 'common/components/BlobbyDatasets/BlobPreview';
import { assetIsPublic } from 'common/views/helpers';

export function mapStateToProps(state) {
  const { view } = state;
  const cannotPreview = { isPreviewable: false };

  if (!view.isBlobby) {
    return cannotPreview;
  }

  if (view.blobType === 'google_viewer' && !assetIsPublic(view.coreView)) {
    return cannotPreview;
  }

  return {
    isPreviewable: true,
    previewUrl: `/api/views/${view.id}/files/${view.blobId}`,
    previewType: view.blobType,
    blobName: view.name
  };
}

export default connect(mapStateToProps)(BlobPreview);

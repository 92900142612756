import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import hasFlag from 'common/views/has_flag';
import { View, ViewFlag } from 'common/types/view';
import I18n from 'common/i18n';

//@ts-expect-error
import Notice from './Notice';

interface FederatedAssetNoticeProps {
  view: View;
}

interface FederatedAssetNoticeState {
  dismissed: boolean;
}

const scope = 'dataset_landing_page';
export class FederatedAssetNotice extends Component<FederatedAssetNoticeProps, FederatedAssetNoticeState> {
  constructor(props: FederatedAssetNoticeProps) {
    super(props);

    this.state = { dismissed: false };
  }

  render() {
    const { view } = this.props;
    const sourceDomainCName = get(view, 'sourceDomainCName');
    const isDataFederatedView = !isNil(view) && hasFlag(view, ViewFlag.InternalToPublicFederatedView);

    if (this.state.dismissed || isEmpty(sourceDomainCName) || !isDataFederatedView) {
      return null;
    }

    return (
      <Notice onClickDismiss={() => this.setState({ dismissed: true })}>
        {I18n.t('this_asset_is_federated_from', { scope, source_domain: sourceDomainCName })}
      </Notice>
    );
  }
}

export const mapStateToProps = (state: any) => {
  return { view: get(state, 'view.coreView') };
};

export default connect(mapStateToProps)(FederatedAssetNotice);

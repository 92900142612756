import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { components as SocrataVisualizations } from 'common/visualizations';
import SearchInput from 'common/components/SearchInput';
import { getSiteChromeHeight, getAssetBarHeight } from 'common/util/siteMeasurements';
import I18n from 'common/i18n';
import AssetBadgeSection from 'common/components/AssetBadgeSection/AssetBadgeSection'

const t = (k, scope = 'dataset_landing_page.dataset_preview') => I18n.t(k, { scope });

// Decide if we'll use setFilter based on base row count of the view
// too many possible values makes load time and interaction too slow.
export const ROW_COUNT_CAP_FOR_SET_FILTERS = 100000;

export class DatasetPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      typing: false,
      typingTimeout: 0,
      currentSearchString: '',
      currentFilter: []
    };
  }

  onChange = (value) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: value,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.setState({ currentSearchString: this.state.searchText }, () => {
          this.renderTable();
          // this horrible hack of selection is needed because of a strange Forge interaction with the
          // AgGrid. After a search, the input box would still have the cursor and accept typing but
          // would no longer be "focused," thus losing its highlighting css.
          const element = document.activeElement.parentElement.shadowRoot?.querySelector('.forge-field');
          if (element) {
            element.classList.add('forge-field--focused');
          }
        });
      }, 500)
    });
  };

  renderSearchBar() {
    return (
      <SearchInput
        onSearch={(value) => {
          this.onChange(value);
        }}
        onChange={this.onChange}
        value={this.state.searchText}
        title={t('search')}
        id="search-view"
        dataTestId="search-primer-table-input"
      />
    );
  }

  renderTable() {
    // add the searchString to the vif
    let { vif, view, onVifUpdate } = this.props;
    if (vif.series) {
      vif = {
        ...vif,
        series: [
          {
            ...vif.series[0],
            dataSource: { ...vif.series[0].dataSource, searchString: this.state.currentSearchString }
          }
        ]
      };
    }

    const defaultColDefOverrides = {
      maxWidth: 5000 // we have to give it a max size so setting it absurdly high
    };

    // This is a temporary way to pass localization information to frontend-visualizations
    // to localize the Table & Pager until the mono-repo is complete.
    const localeOptions = _.has(window, 'serverConfig.locale')
      ? { locale: window.serverConfig.locale }
      : { locale: '' };
    const options = {
      ...localeOptions,
      paginationPageSize: 50,
      defaultColDefOverrides,
      onAgTableVifUpdate: onVifUpdate,
      displayColumnFilters: true,
      useSetFilters: !!view.rowCount && view.rowCount <= ROW_COUNT_CAP_FOR_SET_FILTERS,
      showAgGridColumnMenu: true,
      showAgGridColumnAggregations: false
    };

    return (
      <div className='table-contents-primer-improved'>
        <SocrataVisualizations.Visualization vif={vif} options={options} />
      </div>
    );
  }

  render() {
    const { view, dataSourceDetails } = this.props;
    const isChildView = !!dataSourceDetails;

    const getHeaderOffset = () => {
      const getTabBarHeight = () => {
        const tabBar = document.getElementById('view-switcher-nav-bar');
        if (tabBar) {
          return tabBar.offsetHeight;
        } else {
          return 0;
        }
      };

      const actionBarHeight = getAssetBarHeight();
      const siteChromeHeaderHeight = getSiteChromeHeight();
      const tabBarHeight = getTabBarHeight();
      const totalOffSet = siteChromeHeaderHeight + tabBarHeight + actionBarHeight;
      return { height: `calc(100vh - ${totalOffSet}px)` };
    };

    const parentViewLink = isChildView ? (
      <div className="landing-page-section-header-parent-link">
        {t('view_based_on')}&nbsp;
        <a href={`/d/${dataSourceDetails.parentViewId}`}>{dataSourceDetails.parentViewName}</a>
      </div>
    ) : null;

    const improvedPrimerTable = (
      <div
        className="landing-page-section dataset-preview landing-page-section-primer-improved"
        style={getHeaderOffset()}
      >
        <div className={'landing-page-header-wrapper-primer-improved'}>
          <div className="landing-page-section-header-primer-improved">
            <span className={isChildView ? 'parent-view' : ''}>
              <h2 className="landing-page-section-header-title">{view.name}</h2>
              <span className='landing-page-section-header-badge'>
              <AssetBadgeSection provenance={view.provenance}/>
              </span>
            </span>
            {parentViewLink}
            <span className="right-content">{this.renderSearchBar()}</span>
          </div>
        </div>
        {this.renderTable()}
      </div>
    );

    return improvedPrimerTable;
  }
}

DatasetPreview.propTypes = {
  onClickGrid: PropTypes.func,
  view: PropTypes.object.isRequired,
  vif: PropTypes.object.isRequired,
  onVifUpdate: PropTypes.func
};

function mapStateToProps({ view }) {
  const tableType = 'agTable';
  const dimension = { columns: view.columns };

  const { id: parentViewId, name: parentViewName } = _.get(view, 'parentView') || {};

  const dataSourceDetails =
    parentViewId && parentViewName
      ? {
          parentViewName,
          parentViewId
        }
      : null;

  return {
    view,
    dataSourceDetails,
    vif: {
      format: {
        type: 'visualization_interchange_format',
        version: 3
      },
      configuration: {
        viewSourceDataLink: false
      },
      series: [
        {
          dataSource: {
            datasetUid: view.id,
            dimension: dimension,
            type: 'socrata.soql',
            filters: [],
            hierarchies: [],
            searchString: '',
            // In most contexts, visualizations (including the table) will read from the NBE.
            // However, we have a product requirement here to display the OBE data directly
            // (to avoid confusing the user with mismatches in the column count and types
            // introduced by the obe -> nbe migration).
            readFromNbe: false
          },
          type: tableType,
          unit: {
            one: view.rowLabel,
            other: view.rowLabelMultiple
          }
        }
      ]
    }
  };
}

function mapDispatchToProps() {
  return {
    onClickGrid() {
      // noop since the removal of m*xpanel, but we may need to add some sort of tracking here
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetPreview);

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { defaultHeaders, fetchJson } from 'common/http';

import './PrimerChatbot.scss';
import {
  ForgeButton,
  ForgeCard,
  ForgeDivider,
  ForgeIcon,
  ForgeIconButton,
  ForgeTextField
} from '@tylertech/forge-react';
import {
  getURLFromSoql,
  handleSelection,
  primaryAPICall,
  removeJsonCodeBlock
} from './PrimerChatbotAIHelpers';
import { aiDomainDataProps } from './DataWiz';

const PrimerChatbot = (props: aiDomainDataProps) => {
  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);
  const [chatID, setChatID] = useState(0);
  const [chatHistory, setChatHistory] = useState<
    {
      id: number;
      chatObject: {
        inputs: { domain: string; fourfour: string; question: string; type: number };
        outputs: { answer: string; line_number: number };
      };
      flags: string[];
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState<any>(null);
  /********************************************************/
  /*      This metadata will be passed in via props       */
  /*      Secondary datasets are:                         */
  /*      FourFour: 'qxh8-f4bd', 'w729-m8j6'              */
  /*      Domain: 'internal.opendata.clermontauditor.org' */
  /*      ExploreUrl: 'https://data.wa.gov/Health/Health-Care-Provider-Credential-Data/qxh8-f4bd/explore/query/'
  /********************************************************/
  // const fourfour = 'gpri-47xz';
  // const domain = 'data.wa.gov';
  // const exploreUrl = 'https://data.wa.gov/Consumer-Protection/Attorney-General-Consumer-Complaints/gpri-47xz/explore/query/';
  const { fourfour, domain, exploreUrl } = props;

  const datasetMetadata = {
    fourfour: fourfour,
    domain: domain,
    type: 0,
    question: '',
    chat_history: []
  };

  /***********************************************************/
  /* on function load, this uses the dataset metadata to ask */
  /* the MLL for 4 suggested questions about the dataset     */
  /***********************************************************/
  useEffect(() => {
    const getSuggestedPrompts = () => {
      setLoading(true);
      return primaryAPICall(datasetMetadata).then((response: any) => {
        const answers = removeJsonCodeBlock(response.answer);
        const questions = JSON.parse(answers).questions;
        setLoading(false);
        setSuggestedPrompts(questions);
      });
    };
    getSuggestedPrompts();
  }, []);

  /***********************************************************/
  /* This is an asynchronous function that makes an API call and
  /* updates the application state based on the response.
  /*
  /* @param data {any} - The data to be sent to the API.
  /* @param question {string} - The user's question/input.
  /* @param flags {string[]} - An array of flags to be used in the history chat object.
  /*
  /* The function performs the following steps:
  /* 1. Sets the loading state to true, indicating that the API call is in progress.
  /* 2. Makes an API call with the provided data.
  /* 3. Once the API call is resolved, sets the loading state to false.
  /* 4. Increments the chatID state by 1.
  /* 5. Updates the chatHistory state by adding a new object to the array. This object includes the current chatID, a chatObject with input and output details, and the provided flags.
  /***********************************************************/
  const apiCall = async (data: any, question: string, flags: string[]) => {
    setLoading(true);
    return secondaryAPICall(data).then((response: any) => {
      setLoading(false);
      setChatID(chatID + 1);
      setChatHistory([
        ...chatHistory,
        {
          id: chatID,
          chatObject: {
            inputs: {
              fourfour: fourfour,
              domain: domain,
              question: question,
              type: 0
            },
            outputs: {
              answer: response,
              line_number: 0
            }
          },
          flags: flags
        }
      ]);
    });
  };

  /***********************************************************/
  /* This function, `secondaryAPICall`, is designed to make a API call to a specific endpoint.
  /*
  /* @param data {any} - The data to be sent to the API in the request body.
  /*
  /* The function performs the following steps:
  /* 1. Calls the `fetchJson` function with the URL of the API endpoint and an options object.
  /* 2. The options object includes the HTTP method ('POST'), headers (including default headers, 'Content-Type' set to 'application/json' and 'azureml-model-deployment' set to 'deploy'), credentials set to 'same-origin', and the body of the request (which is the stringified `data`).
  /* 3. Returns the result of the `fetchJson` call, which should be a Promise resolving to the JSON response from the API.
  /**********************************************************/
  const secondaryAPICall = async (data: any) => {
    return fetchJson('/azure_score_proxy/soql2', {
      method: 'POST',
      headers: {
        ...defaultHeaders,
        'Content-Type': 'application/json',
        'azureml-model-deployment': 'deploy'
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    });
  };

  /***********************************************************/
  /*  This currently has no guardrails
  /*  for the user input, bad engineer!
  /* This function, `handleChange`, is designed to handle changes in an input field.
  /*
  /* @param e {any} - The event object from the input field.
  /*
  /* The function performs the following steps:
  /* 1. Checks if there's an existing countdown (timeout). If there is, it clears the countdown.
  /* 2. Sets a new countdown (timeout) that will execute after 2 seconds. This is to prevent the API call from firing on every keystroke.
  /* 3. Inside the countdown, it retrieves the question from the event's target value.
  /* 4. Calls the `handleSelection` function with the question, `chatHistory`, `apiCall`, and `datasetMetadata` as arguments.
  /***********************************************************/
  const handleChange = (e: any) => {
    // we use a countdown to prevent the api call from firing on every keystroke
    if (countdown) clearTimeout(countdown);
    setCountdown(
      setTimeout(() => {
        const question = e.target.value;
        handleSelection(question, chatHistory, apiCall, datasetMetadata);
      }, 2000)
    );
  };

  function onClose(chatId: number): void {
    setChatHistory(chatHistory.filter((chat: any) => chat.id !== chatId));
  }

  /***********************************************************/
  /* This function, `answerCard`, is designed to create a card component for each chat message.
  /*
  /* @param chat {any} - The chat object that includes the chat details.
  /*
  /* The function performs the following steps:
  /* 1. Retrieves the answer from the chat object's outputs.
  /* 2. Calls the `getURLFromSoql` function to extract the URL from the SOQL query.
  /* 3. Constructs the URL by concatenating the `exploreUrl` and `soql`.
  /* 4. Returns a `div` element that includes a `ForgeCard` component. This card includes the question, a button linking to EC with the query applied to the data (if `soql` is not null), a button to close the card, and the answer.
  /***********************************************************/
  const answerCard = (chat: any) => {
    const res = chat.chatObject.outputs.answer;
    const answer = res.answer;
    const soql = res.soql;
    const url = exploreUrl + '/query/' + getURLFromSoql(soql);

    return (
      <div className="demo-card" key={chat.id}>
        <ForgeCard style={{ '--mdc-theme-surface': 'rgb(231, 231, 231)' }}>
          <div className="forge-card-header-container">
            <h3 className="forge-typography--headline6">{chat.chatObject.inputs.question}</h3>
            <div className="button-tray">
              {soql && (
                <ForgeButton type={'flat'}>
                  <button
                    type="button"
                    aria-label="Apply query to data"
                    onClick={() => window.open(url, '_blank')}
                  >
                    <span>{'Apply query to data'}</span>
                    <ForgeIcon name="open_in_browser" />
                  </button>
                </ForgeButton>
              )}
              <ForgeIconButton>
                <button type="button" aria-label="Close">
                  <ForgeIcon name="close" onClick={() => onClose(chat.id)} />
                </button>
              </ForgeIconButton>
            </div>
          </div>

          <div>
            <div className="forge-typography--body2">{answerParagraphs(answer, chat.flags)}</div>
          </div>
        </ForgeCard>
      </div>
    );
  };

  /***********************************************************/
  /* This function, `answerParagraphs`, is designed to format the answer into paragraphs and handle any flags.
  /*
  /* @param answer {string} - The answer string to be formatted.
  /* @param flags {string[]} - An array of flags to be used in the formatting. Default is an empty array.
  /*
  /* The function performs the following steps:
  /* 1. Checks if the flags include 'insights'. If they do, it logs an error message saying this feature is not implemented yet.
  /* 2. Returns a `div` element with a class of 'chat-answers'.
  /* 3. Splits the answer by newline characters to create an array of paragraphs.
  /* 4. Filters out any empty paragraphs.
  /* 5. Maps over each paragraph. If the flags do not include 'insights' or the paragraph is the first or fifth one, it returns a `div` with a class of 'chat-answer' and the paragraph as its content. If not, it returns a `div` with a class of 'chat-answer' and the first two characters of the paragraph, followed by a link with the rest of the paragraph as its text.
  /***********************************************************/
  const answerParagraphs = (answer: any, flags: string[] = []) => {
    if (flags.includes('insights')) {
      console.error('not implemented yet');
      // at this point we have three 'insights', but no answers or soql.
    }
    return (
      <div className="chat-answers">
        {answer
          .split('\n')
          .filter((paragraph: string) => paragraph.length > 0)
          .map((paragraph: string, index: number) => {
            if (!flags.includes('insights') || index == 0 || index == 4) {
              return (
                <div className="chat-answer" key={index}>
                  {paragraph}
                </div>
              );
            } else {
              return (
                <div className="chat-answer" key={index}>
                  {paragraph.substring(0, 2) +
                  (
                    <a href={'url'} target="_blank">
                      paragraph.substring(3)
                    </a>
                  )}
                </div>
              );
            }
          })}
      </div>
    );
  };

  return (
    <section className="primer-chatbot-section">
      <ForgeCard className="AI-info-pane">
        <div className="name-section">
          <h2 className="asset-name">AI Bot generated overview</h2>
        </div>
        <ForgeDivider />
        <div className="description">
          Click a suggested prompt or enter your own to learn about this data.
        </div>
        <div className="suggested-prompts">
          <ForgeButton type="outlined">
            <button
              className="suggested-prompt-btn"
              type="button"
              onClick={() => handleSelection(suggestedPrompts[0], [], apiCall, datasetMetadata)}
            >
              <span>{suggestedPrompts[0]}</span>
            </button>
          </ForgeButton>
          <ForgeButton type="outlined">
            <button
              className="suggested-prompt-btn"
              type="button"
              onClick={() => handleSelection(suggestedPrompts[1], [], apiCall, datasetMetadata)}
            >
              <span>{suggestedPrompts[1]}</span>
            </button>
          </ForgeButton>
          <ForgeButton type="outlined">
            <button
              className="suggested-prompt-btn"
              type="button"
              onClick={() => handleSelection(suggestedPrompts[2], [], apiCall, datasetMetadata)}
            >
              <span>{suggestedPrompts[2]}</span>
            </button>
          </ForgeButton>
          <ForgeButton type="outlined">
            <button
              className="suggested-prompt-btn"
              type="button"
              onClick={() => handleSelection(suggestedPrompts[3], [], apiCall, datasetMetadata)}
            >
              <span>{suggestedPrompts[3]}</span>
            </button>
          </ForgeButton>
        </div>
        <div className="chatOutput">
          {chatHistory.map((chat: any) => {
            return answerCard(chat);
          })}
          {loading && (
            <div className="busy">
              <span className="spinner-default spinner-large" />
            </div>
          )}
        </div>
        <ForgeTextField className="inputField">
          <input
            type="text"
            id="input-text"
            placeholder={'What would you like to know about this dataset?'}
            onChange={(e) => handleChange(e)}
          />
          <label htmlFor="input-textarea" slot="label">
            {'Enter prompt'}
          </label>
        </ForgeTextField>
        <div className="warning">
          <div className="aiDisclaimer">
            *These questions are generated, and answers are collected, automatically by our AI LLM.*
          </div>
          <div className="aiDisclaimer">*This feature is in beta testing!*</div>
        </div>
      </ForgeCard>
    </section>
  );
};

export default PrimerChatbot;
